<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <a-spin :spinning="loading">
        <a-layout style="height: 750px;border: 1px solid #eee;">
          <a-layout-sider style="background: #fff" width="300">
            <div style="height: 60px;background: #f8f8f8;padding: 10px;display: flex;align-items: center;width: 280px;margin-left: 10px;margin-top: 10px;">
              <a-icon type="search" />
              <a-input v-model="searchName" @pressEnter="searchResult" style="margin-left: 9px;" />
            </div>

            <div style="width: 280px;margin-left: 10px;height: 660px;margin-top: 10px;overflow-y: auto;padding: 0 20px">
              <div id="scrollableDiv" style="height: 660px; overflow: auto;">
                <div style="height: 20px;"></div>
                <div v-for="(item,index) in list" @click.stop="clickSingleData(item,index)" style="display:flex;align-items: center;margin-bottom: 5px;">
                  <div style="width: 50px;height: 50px;border-radius: 5px;position: relative">
                    <a-badge :count="item.number" :offset="[0,15]">
                      <img :src="item.avatar" style="width: 50px;height: 50px;"/>
                    </a-badge>
                  </div>
                  <div style="flex: 1;margin-left: 10px;">
                    <div style="font-weight: 600">{{ item.nickname }}</div>
                    <div style="display: flex;align-items: center;justify-content: space-between">
                      <div v-if="item.type == 0" style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;width: 50px">{{item.content}}</div>
                      <div v-if="item.type == 2">[图片]</div>
                      <div style="white-space: nowrap;margin-left: 5rpx">{{item.createTime}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a-layout-sider>
          <a-layout style="background: #f8f8f8">
            <a-layout-content>
              <div style="font-weight: 600;height: 60px;line-height: 60px;padding: 0 10px;">{{ singleData.nickname }}</div>
              <div style="height:calc(100% - 60px);width: 100%;overflow: auto;background: #fff;padding: 0 10px;" id="rightMessageList">
                <template v-if="searchResultList.length"></template>
                <template v-else>
                  <div style="margin-top: 10px;" v-for="(item,index) in rightMessageList" :key="index">
                    <div class="text-center">{{ item.createTime }}</div>
                    <div style="display: flex;align-items: start;justify-content: flex-end;margin-top: 10px;" v-if="item.from == 'my'">
                      <div style="padding: 5px;background: #00a4f8;color: #fff;border-radius: 3px;max-width: calc(100% - 160px);"  v-if="item.type === 0">
                        {{item.content}}
                      </div>
                      <div style="padding: 5px;background: #00a4f8;color: #fff;border-radius: 3px;" v-if="item.type === 2">
                        <!--                        {{item.content}}-->
                        <img style="width: 150px;height: 150px;" :src="item.content" />
                      </div>
                      <div style="width: 50px;height: 50px;margin-left: 5px">
                        <img src="../../../assets/logo.png" style="width: 50px;height: 50px;"/>
                      </div>
                    </div>
                    <div style="display: flex;align-items: start;justify-content: flex-start;margin-top: 10px;" v-else>
                      <div style="width: 50px;height: 50px;margin-right: 5px;">
                        <img :src="singleData.avatar" style="width: 50px;height: 50px;"/>
                      </div>
                      <div style="padding: 5px;background: #00a4f8;color: #fff;border-radius: 3px;max-width: calc(100% - 160px);" v-if="item.type === 0">
                        {{item.content}}
                      </div>
                      <div style="padding: 5px;background: #00a4f8;color: #fff;border-radius: 3px;" v-if="item.type === 2">
<!--                        {{item.content}}-->
                        <img style="width: 150px;height: 150px;" :src="item.content" />
                      </div>
                    </div>


                  </div>
                </template>

              </div>
            </a-layout-content>
            <a-layout-footer style="height: 200px;padding: 0;">
              <a-input v-model="content" @pressEnter="confirmText(0)" placeholder="Please enter blog text" type="textarea" class="zidingyitextArea" style="height: 130px;width: calc(100% - 20px);margin-left: 10px;margin-right: 10px;margin-top: 10px" allow-clear />
<!--              <a-textarea placeholder="textarea with clear icon" allow-clear style="height: 150px;width: calc(100% - 20px);margin-left: 10px;margin-right: 10px;margin-top: 10px" @change="onChange" />-->

              <div style="display: flex;justify-content: flex-end;align-items: center;margin-top: 10px;padding: 0 10px;">
                <a-button @click.stop="confirmText(0)">Send</a-button>
              </div>
            </a-layout-footer>
          </a-layout>
        </a-layout>
      </a-spin>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageLeave,listLeave, delLeave,getGroupUserInfoList ,addLeave} from '@/api/cigarette/leave'

export default {
  name: 'Leave',
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        userId: null,
        receiveId: null,
        content: null,
        type: null,
        status: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '${comment}',
          dataIndex: 'id',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '备注',
          dataIndex: 'remark',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '发送者的用户id',
          dataIndex: 'userId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '接收者的用户id',
          dataIndex: 'receiveId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '内容',
          dataIndex: 'content',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '类型：0文本 1-表情 2-图片 3-视频',
          dataIndex: 'type',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '是否已读 0-未读 1-已读',
          dataIndex: 'status',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
      rightMessageList:[],
      singleData: null,
      content: "",
      singleIndex:0,
      searchName:"",
      searchResultList:[]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  mounted() {
    // document.getElementById('rightMessageList').addEventListener('DOMSubtreeModified', function() {
    //   this.scrollToBottom(this);
    // });

    // const scrollableDiv = document.getElementById('scrollableDiv');
    // scrollableDiv.scrollTop = scrollableDiv.scrollHeight;
    // console.log(scrollableDiv.scrollHeight)
  },
  methods: {
    searchResult() {
console.log('进入搜索')
      this.searchResultList = this.list.filter(i => i.nickname.indexOf(this.searchName) != -1);
if(this.searchResultList.length === 0) {
  this.$message.info('No results found in the search')
}
      this.searchName = "";
    },
    scrollToBottom(element) {
      element.scrollTop = element.scrollHeight - element.clientHeight;
    },
    confirmText(num) {
      if(this.content) {
        let ItemMessage = {
          content:this.content,
          type:num,
          status:0,
          receiveId:this.singleData.userId
        }
        addLeave(ItemMessage).then(res => {
          if(res.success) {
            res.data.from = 'my';
            this.rightMessageList.push(res.data);
            this.content = "";
            this.list[this.singleIndex].content = res.data.content;
            this.list[this.singleIndex].type = res.data.type;
            this.list[this.singleIndex].createTime = res.data.createTime;
            //
            setTimeout(() => {
              //
              const scrollableDiv = document.getElementById('rightMessageList');
              scrollableDiv.scrollTop = scrollableDiv.scrollHeight;
            },500)
          } else {
            this.$message.info(res.message);
          }
        })
      }

    },
    /** 查询新-用户留言列表 */
    getList () {
      this.loading = true
      getGroupUserInfoList(this.queryParam).then(response => {
         this.list = response.data
        if(this.list.length !== 0) {
          this.singleIndex = 0;
          this.singleData = this.list[0];
          this.$set(this.list[0],'number',0);
          this.getRightMessage(this.list[0].userId)
        } else {
          this.singleData = null;
        }
         // this.total = response.data.total
        this.loading = false
       })
    },
    clickSingleData(data,index) {
      this.$set(data,'number',0);
      this.singleIndex = index;
      this.singleData = data;
      this.getRightMessage(this.singleData.userId)
    },

    getRightMessage(userId) {
      listLeave({
        receiveId:userId
      }).then(res => {
        this.rightMessageList = res.data;
        setTimeout(() => {
          //
          const scrollableDiv = document.getElementById('rightMessageList');
          scrollableDiv.scrollTop = scrollableDiv.scrollHeight;
        },500)
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        userId: undefined,
        receiveId: undefined,
        content: undefined,
        type: undefined,
        status: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delLeave(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('cigarette/user-leave/export', {
            ...that.queryParam
          }, `新-用户留言_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
<style scoped>
.zidingyitextArea {
  height: 140px!important;
}
/deep/.ant-input-affix-wrapper.ant-input-affix-wrapper-textarea-with-clear-btn .ant-input {
  height: 140px!important;
}
</style>
